
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutgPJAJgaekGMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/about.vue?macro=true";
import { default as balanceprojectionvoMJueXnf0Meta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/balanceprojection.vue?macro=true";
import { default as disclosureBtZx4yZzhKMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/disclosure.vue?macro=true";
import { default as hsaguidedportfolioJFV5D1qXTHMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/hsaguidedportfolio.vue?macro=true";
import { default as indexB7qUatM5xQMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/index.vue?macro=true";
import { default as payrollsavingscalculatorHZUplORBOCMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/payrollsavingscalculator.vue?macro=true";
import { default as balprojcalc_45basicYBsUtIkLrgMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/balprojcalc-basic.vue?macro=true";
import { default as balprojcalcTtOIeILw2jMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/balprojcalc.vue?macro=true";
import { default as expensesfksGNkLe9nMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/glossary/expenses.vue?macro=true";
import { default as healthwmLpUiSqcNMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/glossary/health.vue?macro=true";
import { default as investmentaLkmjb6ZjhMeta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/glossary/investment.vue?macro=true";
import { default as hsaeligiblePkup9uVgu0Meta } from "/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/hsaeligible.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutgPJAJgaekGMeta || {},
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/about.vue")
  },
  {
    name: "balanceprojection",
    path: "/balanceprojection",
    meta: balanceprojectionvoMJueXnf0Meta || {},
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/balanceprojection.vue")
  },
  {
    name: "disclosure",
    path: "/disclosure",
    meta: disclosureBtZx4yZzhKMeta || {},
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/disclosure.vue")
  },
  {
    name: "hsaguidedportfolio",
    path: "/hsaguidedportfolio",
    meta: hsaguidedportfolioJFV5D1qXTHMeta || {},
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/hsaguidedportfolio.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexB7qUatM5xQMeta || {},
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/index.vue")
  },
  {
    name: "payrollsavingscalculator",
    path: "/payrollsavingscalculator",
    meta: payrollsavingscalculatorHZUplORBOCMeta || {},
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/payrollsavingscalculator.vue")
  },
  {
    name: "tools-balprojcalc-basic",
    path: "/tools/balprojcalc-basic",
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/balprojcalc-basic.vue")
  },
  {
    name: "tools-balprojcalc",
    path: "/tools/balprojcalc",
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/balprojcalc.vue")
  },
  {
    name: "tools-glossary-expenses",
    path: "/tools/glossary/expenses",
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/glossary/expenses.vue")
  },
  {
    name: "tools-glossary-health",
    path: "/tools/glossary/health",
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/glossary/health.vue")
  },
  {
    name: "tools-glossary-investment",
    path: "/tools/glossary/investment",
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/glossary/investment.vue")
  },
  {
    name: "tools-hsaeligible",
    path: "/tools/hsaeligible",
    component: () => import("/codebuild/output/src167086114/src/hsaplanner-marketing/pages/tools/hsaeligible.vue")
  }
]